<template>
  <div class="main">
    <div class="banner">
      <div class="img_box" style="display:flex;justify-content: center;overflow: hidden;">
        <img
          src="../../assets/image/trade_banner.png"
          alt=""
        >
      </div>
      <div class="banner_info">
        <h3>外贸建站</h3>
        <p>建立网站营销高速通道</p>
        <div class="banner_info_btn"><button>立即咨询</button></div>
      </div>
    </div>
    <div class="trade_wrap">
      <div class="trade_header">
        <div class="trade_header_nav">
          <ul>
            <li
              @click=change(1)
              :class='currentIndex==1?"active":""'
            ><span>项目案例</span></li>
            <li
              @click=change(2)
              :class='currentIndex==2?"active":""'
            ><span>套餐价格</span></li>
            <li
              @click=change(3)
              :class='currentIndex==3?"active":""'
            ><span>建站特色</span></li>
          </ul>
        </div>
        <div class="case_wrap" v-show="currentIndex==1">
          <div class="case_tag_box">
            <ul class="margin-top-20">
              <li class="margin-bottom-20"  v-for="item in caseTagList" :key="item.tagName">
                <span class="dropdown_toggle" :class="tagActive == item.tagName ? 'dropdown_toggle_active' : ''" @click="handleTagChange(item)">{{ item.tagName }}</span>
              </li>
            </ul>
          </div>
          <div class="case_con">
            <li class="case_con_list" v-for="item in caseList" :key="item.id">
              <div class="case_con_list_imgbox">
                <div class="imgbox_img">
                  <img
                    :src="item.preview_url"
                    alt=""
                  >
                </div>
                <a class="imgbox_mask" :href="item.website_url" target="_blank">{{ item.name}}</a>
              </div>
            </li>
            <button v-if="totalRow > caseList.length" class="case_con_btn" @click="getMoreCase">更多案例</button>
            <button v-else class="case_con_btn" style="background-color: #ff8800;border-color: #ff8800;">暂无更多</button>
          </div>
        </div>
        <div class="package_wrap" v-show="currentIndex==2">
          <div class="package_form">
            <div class="package_form_left">
              <ul style="border-left: 1px solid rgba(177, 177, 177, 0.5);">
                <li class="form_left_title">价格与服务</li>
                <li class="form_left_con">基本功能</li>
                <li class="form_left_flex">
                  <div class="left_flex_con">
                    <span class="left_flex_title">前端设计</span>
                  </div>
                  <div class="left_flex_con">
                    <span>比例自动适应</span>
                    <span>HTML5特效</span>
                    <span>LOGO上传</span>
                    <span>HTML静态化页面</span>
                    <span>语言版本切换</span>
                    <span>防止复制功能</span>
                    <span>手机版/平版端</span>
                    <span>301重定向</span>
                    <span>404页面</span>
                    <span>水印设置</span>
                    <span>欧美模板</span>
                    <span>首页广告图设计</span>
                    <span>内页广告图设计</span>
                  </div>
                </li>
                <li class="form_left_flex">
                  <div class="left_flex_con">
                    <span class="left_flex_title">后台功能</span>
                  </div>
                  <div class="left_flex_con">
                    <span>产品管理</span>
                    <span>页面管理</span>
                    <span>文章管理</span>
                    <span>FAQ管理</span>
                    <span>PDF下载</span>
                    <span>询盘管理</span>
                    <span>广告管理</span>
                    <span>友情链接</span>
                    <span>SNS链接</span>
                    <span>SNS分享</span>
                    <span>会员中心</span>
                    <span>商品管理</span>
                    <span>商城配置</span>
                    <span>数据统计</span>
                    <span>图片水印</span>
                  </div>
                </li>
                <li class="form_left_con">推广营销</li>
                <li class="form_left_flex">
                  <div class="left_flex_con">
                    <span class="left_flex_title">SEO优化</span>
                  </div>
                  <div class="left_flex_con">
                    <span>结构优化</span>
                    <span>关键词优化</span>
                    <span>Meta优化</span>
                    <span>生成sitemap</span>
                    <span>图片优化</span>
                    <span>URL优化</span>
                    <span>提交收录</span>
                  </div>
                </li>
                <li class="form_left_con">数据统计</li>
                  <li class="form_left_flex">
                  <div class="left_flex_con">
                    <span class="left_flex_title">数据魔方</span>
                  </div>
                  <div class="left_flex_con">
                    <span>访问量统计</span>
                    <span>网站排名</span>
                    <span>网站PR值</span>
                  </div>
                </li>
                <li class="form_left_con">售后</li>
                <li class="form_left_flex">
                  <div class="left_flex_con">
                    <span class="left_flex_title">售后服务</span>
                  </div>
                  <div class="left_flex_con">
                    <span>专属客服</span>
                    <span>运行保障</span>
                    <span>安全保障</span>
                    <span>数据备份</span>
                    <span>程序维护</span>
                  </div>
                </li>
                <li class="form_left_con" style="height: 40px">价格说明</li>
                <!-- <li class="downloadChange">下载产品资料</li> -->
              </ul>
            </div>
            <div class="package_form_right">
              <ul class="standard_edition">
                <li class="form_right_title">
                  <p class="package_title">B2C官网</p>
                  <p>
                    <span class="package_price">¥5680</span><span class="package_unit">元/年</span>
                  </p>
                </li>
                <li
                  v-for="item in standardEdition"
                  :key="item.index"
                  class="form_right_con"
                >{{ item }}</li>
                <li class="consulting_btn" style="border-left: 1px solid rgba(177, 177, 177, 0.5);">
                  <el-button
                    type="danger"
                    round
                    class="serviceBtn"
                  >立即咨询</el-button>
                </li>
              </ul>
              <ul class="advanced_edition">
                <li class="form_right_title">
                  <p class="package_title">B2C商城</p>
                  <p>
                    <span class="package_price">¥9680</span><span class="package_unit">元/年</span>
                  </p>
                </li>
                <li
                  v-for="item in advancedEdition"
                  :key="item.index"
                  class="form_right_con"
                >{{ item }}</li>
                <li class="consulting_btn">
                  <el-button
                    type="danger"
                    round
                    class="serviceBtn"
                  >立即咨询</el-button>
                </li>
              </ul>
              <!-- <ul class="professional_edition">
                <li class="form_right_title">
                  <p class="package_title">专业版套餐</p>
                  <p>
                    <span class="package_price">¥6999</span><span class="package_unit">元/年</span>
                  </p>
                </li>
                <li
                  v-for="item in professionalEdition"
                  :key="item.index"
                  class="form_right_con"
                >{{ item }}</li>
                <li class="consulting_btn">
                  <el-button
                    type="danger"
                    round
                    class="serviceBtn"
                  >立即咨询</el-button>
                </li>
              </ul> -->

            </div>
          </div>
        </div>
        <div class="characteristic_wrap" v-show="currentIndex==3">
          <div class="characteristic_modular">
            <h2 style="margin-bottom: 25px;">90%外贸企业的网站不适合做外贸</h2>
            <ul class="develop_con_ul">
              <li
                class="develop_con_li"
                v-for="item in developList"
                :key="item.title"
              >
                <div class="con_li_img">
                  <img
                    :src="item.serviceIcon"
                    alt="#"
                  >
                </div>
                <div class="develop_li_content">
                  <p class="develop_li_title">{{item.title}}</p>
                  <p class="develop_li_text">{{item.text}}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="characteristic_modular">
            <h2>90%以上的传统企业网站零盈利</h2>
            <h2 style="margin-bottom:25px;">根本不适合做网络营销</h2>
            <div class="characteristic_center">
              <div
                class="boxA_edge"
                style="text-align: right;"
              >
                <h3>专业建站</h3>
                <p>专业SEO优化将显著提升网站搜索排名</p>
                <p>网站定位更精准，提升客户询盘，大量节省运营费用</p>
                <p>专业线路优化，网站加载快，信息展示永远快人一步</p>
                <p>专业设计团队，高效布局客户需求点，塑造高端品牌形象</p>
              </div>
              <div class="boxA_center">
                <h3>VS</h3>
                <span>SEO优化</span>
                <span>网站定位</span>
                <span>访问速度</span>
                <span>页面布局</span>
              </div>
              <div
                class="boxA_edge"
                style="text-align: left;"
              >
                <h3>传统企业网站</h3>
                <p>没有专业SEO，网站等于白做</p>
                <p>网站定位不精准，浪费流量费用</p>
                <p>缺少网络优化，网站打开速度慢，跳失率高</p>
                <p>页面布局随意，视觉体验差，降低客户对品牌信赖度</p>
              </div>
            </div>
          </div>
          <div
            class="characteristic_modular"
            style="background: #F0F4FC; padding: 40px 0;"
          >
            <h2>企贸通营销型网站系统</h2>
            <h2 style="margin-bottom:25px;">从营销出发，建立网站营销高速通道</h2>
            <div
              class="characteristic_center"
              style="justify-content: space-between;"
            >
              <div class="box_conter">
                <h3>01</h3>
                <h3>多终端自适应设计</h3>
                <p style="padding-top: 20px;">智能适配PC、手机、pad各尺寸屏幕</p>
                <p>一站管理：多渠道同步，快速便捷</p>
                <p>高品质网页设计</p>
                <p>海量精美模板一键替换</p>
              </div>
              <div class="box_conter">
                <h3>02</h3>
                <h3>自动化营销系统</h3>
                <p style="padding-top: 20px;">多关键词布局系统</p>
                <p>SEO智能管理及优化</p>
                <p>二维码营销系统</p>
                <p>智能询盘引导系统</p>
                <p>社媒营销速成系统</p>
                <p>专业流量分析系统</p>
              </div>
              <div class="box_conter">
                <h3>03</h3>
                <h3>特色管理功能</h3>
                <p style="padding-top: 20px;">产品管理系统</p>
                <p>新闻管理系统</p>
                <p>下载管理系统</p>
                <p>图片管理系统</p>
                <p>询盘管理系统</p>
                <p>B2B帐号管理系统</p>
                <p>社媒帐号管理系统</p>
              </div>
            </div>
          </div>
          <div
            class="characteristic_modular"
            style="padding-bottom: 70px;"
          >
            <h2>简单便捷 轻松5步</h2>
            <div
              class="characteristic_center"
              style="justify-content: space-between;"
            >
              <div class="step_box">
                <h4>Step1</h4>
                <h4>注册登记</h4>
                <p>填写个人资料，注册成为企贸通会员</p>
              </div>
              <div class="step_box">
                <h4>Step2</h4>
                <h4>购买产品</h4>
                <p>根据需要，您可以选择免费体验，也可以直接购买</p>
              </div>
              <div class="step_box">
                <h4>Step3</h4>
                <h4>平台建设</h4>
                <p>根据指引建设多语言多终端营销平台</p>
              </div>
              <div class="step_box">
                <h4>Step4</h4>
                <h4>营销推广</h4>
                <p>借助智能化营销系统对平台进行全方位的推广</p>
              </div>
              <div class="step_box">
                <h4>Step5</h4>
                <h4>运营管理</h4>
                <p>利用智能化管理工具对平台进行运营和管理</p>
              </div>
            </div>
            <el-button
              type="danger"
              class="construction_btn"
            >我要建站</el-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { templateListPage, templateListTags } from '@/api/mall'
export default {
  data() {
    return {
      currentIndex: 1,
      caseList: [],
      caseTagList: [],
      standardEdition: [
        "",
        "✔",
        "✔",
        "✔",
        "✔",
        "一个语言",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "1套",
        "3张",
        "3张",
        "30个",
        "✔",
        "10个",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "",
        "",
        "",
        "",
        "",
        "",
        "智能+人工优化",
        "",
        "✔",
        "✔",
        "✔",
        "",
        "✔",
        "✔",
        "✔",
        "每一个月",
        "✔",
        "当前价格不包含修图服务，如有需要另外付费"
      ],
      advancedEdition: [
        "",
        "✔",
        "✔",
        "✔",
        "✔",
        "一个语言",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "3套",
        "5张",
        "5张",
        "30个",
        "✔",
        "10个",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "",
        "智能+人工优化",
        "",
        "✔",
        "✔",
        "✔",
        "",
        "✔",
        "✔",
        "✔",
        "每一个月",
        "✔",
        "当前价格不包含修图服务，如有需要另外付费"
      ],
      professionalEdition: [
        "1699元/年",
        "",
        "✔",
        "✔",
        "✔",
        "✔",
        "两个语言",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "5张",
        "5张",
        "150个",
        "✔",
        "150个",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "✔",
        "",
        "智能优化+人工优化",
        "",
        "✔",
        "✔",
        "✔",
        "",
        "✔",
        "✔",
        "✔",
        "每一个月",
        "✔"
      ],
      developList: [
        {
          serviceIcon: require("../../assets/image/trade_icon01.png"),
          title: "打开速度慢",
          text: "网站未曾打开，访客已经关闭"
        },
        {
          serviceIcon: require("../../assets/image/trade_icon02.png"),
          title: "页面设计差",
          text: "一看就是个小公司的网站"
        },
        {
          serviceIcon: require("../../assets/image/trade_icon03.png"),
          title: "结构混乱",
          text: "寻找半天，也找不到想要看的内容"
        },
        {
          serviceIcon: require("../../assets/image/trade_icon04.png"),
          title: "缺乏优化",
          text: "茫茫网海，Google前几页难觅踪影"
        },
        {
          serviceIcon: require("../../assets/image/trade_icon05.png"),
          title: "盲目运营",
          text: "没有网站数据分析，不懂针对性做战略性调整"
        },
        {
          serviceIcon: require("../../assets/image/trade_icon06.png"),
          title: "手机无法浏览",
          text: "用手机阅读密密麻麻的PC站内容，让人想在一秒内关掉"
        }
      ],
      casePage: 1,
      totalRow: 0,
      tagActive: '',
      totalPage: ''
    };
  },
  created() {
    templateListPage({pageIndex: this.casePage, pageSize: 6,}).then((res) => {
      this.caseList = res.data.list
      this.totalPage = res.data.totalPage
      this.totalRow = res.data.totalRow
      this.casePage ++ 
    }) 
    templateListTags().then((res) => {
      this.caseTagList = res.data
    }) 
  },
  methods: {
    change(index) {
      this.currentIndex = index;
    },
    getMoreCase() {
      templateListPage({pageIndex: this.casePage, pageSize: 6, tag: this.tagActive}).then((res) => {
        this.caseList.push.apply(this.caseList, res.data.list)
        this.totalPage = res.data.totalPage
        this.totalRow = res.data.totalRow
        this.casePage ++ 
      }) 
    },
    handleTagChange(item) {
      this.tagActive = item.tagName
      this.casePage = 1
      templateListPage({pageIndex: this.casePage, pageSize: 6, tag: item.tagName}).then((res) => {
        this.caseList = res.data.list
        this.totalPage = res.data.totalPage
        this.totalRow = res.data.totalRow
        this.casePage ++ 
      }) 
    }
  }
};
</script>
<style scoped>
@import "./trade.css";
</style>
<style scoped>
.banner {
  width: 100%;
  position: relative;
}
.banner img {
  /* width: 100%; */
  height: 540px;
}
.banner_info h3 {
  font-size: 50px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.banner_info p {
  width: 360px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 10px 0 40px 0;
}
.banner_info_btn button {
  width: 185px;
  height: 55px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #979797;
  color: #ff0610;
}
.banner_info {
  max-width: 1200px;
  width: 1200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
}
.trade_wrap {
  /* max-width: 1200px; */
  margin: auto;
  padding-top: 70px;
    background-color: #f7f7f7;
}
.trade_header_nav ul {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 40px;
}
.trade_header_nav ul li {
  width: 132px;
  line-height: 40px;
  background: transparent;
  border-radius: 20px;
  cursor: pointer;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}
.trade_header_nav ul .active {
  color: #fff;
  background: #ff0610;
}

.case_wrap {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 70px;
}

.case_con {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-block: 50px;
}
.case_con_btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 286px;
  height: 55px;
  background: #ff0610;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.case_con_list {
  width: 377px;
  height: 450px;
  position: relative;
  margin-bottom: 35px;
  margin-right: 34px;
}
.case_con_list:nth-child(3n) {
  margin-right: 0;
}
.case_con_list_imgbox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.case_con_list_imgbox:hover .imgbox_img {
  box-shadow: 0px 2px 7px 4px rgba(95, 95, 95, 0.5);
}
.case_con_list_imgbox .imgbox_img {
  width: 100%;
  height: 400px;
  transition: all 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.imgbox_img img {
  width: 100%;
  height: 100%;
}
.case_con_list .imgbox_mask {
  width: 330px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333;
  text-align: center;
  border: 1px solid #c6c6c6;
  cursor: pointer;
}
</style>