import request from '@/utils/request'

export function templateListPage(data) {
  return request({
    url:'/template/listPage',
    method:'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
export function templateListTags() {
  return request({
    url:'/template/listTags',
    method:'get'
  })
}